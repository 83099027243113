<template>
    <div class="box box_mm">
        <div class="box_main">
            <div class="cont_main_box" style="width: 50%;">
            	<el-form ref="form" :model="form" label-width="100px">
            	  <el-form-item label="生日祝福文案">
					    <el-input type="textarea" v-model="form.delivery" :rows="6"></el-input>
            	  </el-form-item>
				  <el-form-item label="发送方式">
				    <el-radio-group v-model="form.delivery2">
				          <el-radio label="短信"></el-radio>
				          <el-radio label="信息盒子"></el-radio>
				    </el-radio-group>
				  </el-form-item>
            	</el-form>
				
				<div style="margin-top: 180px;display: flex;align-items: center;">
					<el-button type="primary" @click="onSubmit">保存</el-button>
				</div>
            </div>
        </div>

    </div> 
</template>

<script>
export default {
    data() {
        return {
			form: {
			  delivery: '',
			  delivery2: '短信',
			  delivery3: true,
			  delivery4: true,
			  delivery5: true,
			  delivery6: true,
			  delivery7: true,
			}
        }
    },
    mounted() {
        
    },
    methods: {
		onSubmit(){
			this.$message.success('保存成功')
		}
    }
}
</script>

<style lang="scss" scoped>
.box_main {
    background-color: #fff;
    width: 100%;
    box-sizing: border-box;
    min-height: 100%;
	overflow: hidden;
	.cont_main_box{
		padding: 0.5rem;
	}
}
</style>